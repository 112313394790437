import React, { useEffect, useRef } from 'react'
import { Input, useAppContext } from '@ftdr/blueprint-components-react'
import { ButtonComponent as Button, TextComponent as Text } from 'src/components/custom-fdr-components'
import { Controller, useForm } from 'react-hook-form'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { yupResolver } from '@hookform/resolvers/yup'
import { addCilFormCreateSchema } from './validation'

interface IProps {
  onClose: () => void
  propTenant: string
}

interface IForm {
  price: string
}

const AddCilForm: React.FC<IProps> = ({ onClose, propTenant }) => {
  const ref = useRef<HTMLInputElement>(null)

  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    addItemToShoppingCart,
    cilValue,
    updateCilValue,
    total,
  } = useShoppingCartContext()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onChange',
    resolver: yupResolver(addCilFormCreateSchema(total, cilValue)),
    defaultValues: { price: cilValue > 0 ? `${cilValue / 100}` : '' },
  })
  const submitForm = (data: IForm) => {
    cilValue === 0
      ? addItemToShoppingCart({
        sku: `${propTenant}-CIL`,
        vendor: propTenant,
        unitPrice: Number(data.price) * 100,
        isTax: false,
        category: 'CIL',
        subcategory: 'Cash In Lie Value',
        description: 'Cash In Lie Value',
        isAvailableForZip: true,
      })
      : updateCilValue(`${Number(data.price) * 100}`, `${propTenant}-CIL`)

    onClose()
  }
  useEffect(() => ref.current?.focus(), [])
  return (
    <div className='max-w-md'>
      <Controller
        name='price'
        control={control}
        render={({ field }) => (
          <Input
            inputRef={ref}
            id='outright-payment-form-price'
            error={errors.price?.message}
            inputMode='decimal'
            startEnhancer={() => <Text>$</Text>}
            value={field.value}
            formField
            formFieldClassName='w-full sm:w-auto md:w-56 mb-6'
            label={localizedText('LABEL_PRICE')}
            className='w-full'
            onKeyPress={(e) => {
              e.key === 'Enter' && handleSubmit(submitForm)()
            }}
            onChange={(e) => {
              const val = e.target.value
              const step1Regex = /^\d+\.?$/
              const step2Regex = /^\d+(\.\d{1,2})?$/

              if (val === '' || step1Regex.test(val) || step2Regex.test(val)) {
                field.onChange(e)
              }
            }}
            onBlur={(e) => {
              e.target.value = Number(e.target.value).toFixed(2)
              field.onChange(e)
              field.onBlur()
            }}
          />
        )}
      />

      <div className='flex mt-8'>
        <Button
          label={localizedText('BUTTON_ADD_TO_CART')}
          size='medium'
          onClick={() => {
            handleSubmit(submitForm)()
          }}
        />
        <Button
          className='ml-6'
          label={localizedText('BUTTON_CANCEL')}
          size='medium'
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default AddCilForm
