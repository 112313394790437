import yup from '../../../utils/yup'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'

const { DEFAULT_ERROR, CIL_ERROR } = textTemplatesEnglish

export const addCilFormCreateSchema = (total: number, cilValue: number) =>
  yup.object().shape({
    price: yup
      .string()
      .required(DEFAULT_ERROR)
      .test(
        'total <= cil',
        CIL_ERROR,
        (val) => Number(val) * 100 <= total + cilValue
      ),
  })
