import React, { useMemo } from 'react'
import {
  Checkbox,
  Input,
  Select,
  SelectHandler,
  SelectOption,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import { useForm, Controller } from 'react-hook-form'
import { emptyItem } from 'src/components/adminForms/initials'
import { MenuItem } from 'src/utils/shared-types'
import { reasons } from './reasons'
import { useShoppingCartContext } from 'src/hooks/use-shopping-cart-context'
import { adjustPriceSchema } from './validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { isHSA } from 'src/utils/tenant-helper'

interface IForm {
  price: string
  isTax: boolean
  reason: MenuItem
}
interface IProps {
  onClose: () => void
}
const AdjustPriceForm: React.FC<IProps> = ({ onClose }) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    currentItem,
    setShoppingCartData,
    shoppingCartData,
    tax: taxtotal,
  } = useShoppingCartContext()
  const initValues = useMemo(
    () => ({
      price: `${
        currentItem.price.unitPrice
          ? (currentItem.price.unitPrice / 100).toFixed(2)
          : 0
      }`,
      isTax: !!currentItem.unitTax,
      reason:
        reasons.find((r) => r.value === currentItem.price.overrideReason) ||
        emptyItem,
    }),

    [currentItem]
  )
  const {
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IForm>({
    mode: 'onTouched',
    resolver: yupResolver(adjustPriceSchema),
    defaultValues: initValues,
  })
  const price = watch('price')
  const overrideReason = watch('reason')
  const isTax = watch('isTax')

  const submitForm = (data: IForm) => {
    const tax =
      data.isTax && taxtotal.taxTotal
        ? Number(
            (
              Number(data.price) *
              100 *
              (Number(taxtotal.taxTotal) / 100)
            ).toFixed(2)
          )
        : 0
    const bData = shoppingCartData.map((item) =>
      item.item.sku === currentItem.item.sku
        ? {
            ...item,
            total: +//Error NaN
            (
              (Number(data.price) * 100 + tax) *
              Number(currentItem.quantity)
            ).toFixed(2),
            unitTax:
              data.isTax && taxtotal.taxTotal
                ? +(
                    Number(data.price) *
                    100 *
                    (Number(taxtotal.taxTotal) / 100)
                  ).toFixed(2)
                : 0,
            price: {
              ...item.price,
              unitPrice: +Number(data.price).toFixed(2) * 100,
              overrideReason: overrideReason.value,
            },
          }
        : item
    )
    setShoppingCartData(bData)
    onClose()
  }
  return (
    <div className="max-w-md">
      <Text
        className="mb-6"
        textTemplateKey="CONTINUE_REPLACEMENT_ADJUST_PRICE"
      />

      <Controller
        name="price"
        control={control}
        render={({ field }) => (
          <Input
            id="price-input"
            inputMode="decimal"
            startEnhancer={() => <Text>$</Text>}
            error={errors?.price?.message}
            value={field.value}
            formField
            formFieldClassName="w-full sm:w-auto md:w-56 mb-2"
            label={localizedText('PRICE_ADJUSTED_LABEL')}
            placeholder="00.00"
            className="w-full"
            onChange={(e) => {
              const val = e.target.value
              let step1Regex
              let step2Regex

              if (currentItem.item.sku?.includes('CIL')) {
                step1Regex = /^-$/
                step2Regex = /^(-?\d+)(\.(\d{1,2})?)?$/
              } else {
                step1Regex = /^\d+\.?$/
                step2Regex = /^\d+(\.\d{1,2})?$/
              }
              if (val === '' || step1Regex.test(val) || step2Regex.test(val)) {
                field.onChange(e)
              }
            }}
            onBlur={(e) => {
              e.target.value = Number(e.target.value).toFixed(2)
              field.onChange(e)
              field.onBlur()
            }}
          />
        )}
      />
      <Controller
        name="isTax"
        control={control}
        render={({ field }) => (
          <Checkbox
            value=""
            color={color}
            checked={field.value}
            onChange={field.onChange}
            required={false}
            label={localizedText('PRICE_TAX_LABEL')}
          />
        )}
      />
      <Controller
        name="reason"
        control={control}
        render={({ field }) => (
          <Select
            id="outright-payment-form-state"
            error={errors.reason?.message}
            autoComplete={false}
            selected={field.value}
            formField
            label={localizedText('PRICE_REASON_LABEL')}
            className="w-full sm:w-auto md:w-56 mb-0 mt-6"
            options={reasons}
            onSelect={field.onChange as SelectHandler<SelectOption>}
            onBlur={field.onBlur}
            placeholder="Select a reason"
          />
        )}
      />
      <div className="flex mt-8">
        <Button
          label={localizedText('BUTTON_OVERRIDE')}
          size="medium"
          disabled={
            (`${(Number(price) * 100).toFixed(2)}` ===
              `${currentItem.price.unitPrice?.toFixed(2)}` &&
              isTax === Boolean(currentItem.unitTax)) ||
            overrideReason.value === ''
          }
          onClick={handleSubmit(submitForm)}
        />
        <Button
          className="ml-6"
          label={localizedText('BUTTON_CANCEL')}
          size="medium"
          onClick={onClose}
        />
      </div>
    </div>
  )
}

export default AdjustPriceForm
