import React, { useState, useMemo } from 'react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import {
  IconWarningCircledFull,
  Table,
  TableColumn,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import Tooltip from 'src/components/tooltip/tooltip'
import TooltipContent from 'src/components/tooltip/tooltip-content'
import Icon from 'src/screens/outright-payment-temp/shopping-cart/icon'
import { IconQuestionMarkCircleFull } from '@ftdr/blueprint-components-react'
import { Link } from 'react-router-dom'
import AgentReplacementConfiramtionInfoList from 'src/components/agent-replacement-confiramation-info-list/agent-replacement-confirmation-info-list'
import { usePaymentContext } from 'src/hooks/use-payment-context'
import { useFormsContext } from 'src/hooks/use-forms-context'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import { api } from 'src/utils/api'
import {
  IShoppingCartData,
  useShoppingCartContext,
} from 'src/hooks/use-shopping-cart-context'
type TStatus = 'error' | 'loading' | 'success' | null

const OutrightPaymentSuccess = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const { savedReplacementId, savedTenant } = usePaymentContext()
  const { shoppingCartData } = useShoppingCartContext()
  const [status, setStatus] = useState<TStatus>(null)
  const [message, setMessage] = useState<string | null>(null)
  const { formType } = useFormsContext()

  const itemUnavailable = shoppingCartData.some(
    (obj) => !obj.item.isAvailableForZip
  )

  const transformArray = (data: IShoppingCartData[]) => {
    return data.map(({ quantity, total, item, price, unitTax }) => ({
      item,
      quantity,
      unitTax: { unitTax, quantity },
      price: { ...price, quantity },
      total: { total, quantity },
    }))
  }

  const dataToDisplay = transformArray(shoppingCartData)

  const handleOrder = async () => {
    setStatus('loading')
    setMessage(null)
    if (savedReplacementId) {
      try {
        await api.createOrder(savedReplacementId, savedTenant || '')
        setStatus('success')
        setMessage('Your order is successful.')
      } catch (e: any) {
        console.log(e.message)
        setMessage(e.message)
        setStatus('error')
      }
    }
  }

  const columns = useMemo<TableColumn<any[]>[]>(
    () => [
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_ITEM"
          />
        ),
        accessor: 'item', // accessor is the "key" in the data
        Cell: ({ value }: any) => {
          return (
            <div
              className="cell-one"
              style={{ maxWidth: 580, opacity: value.quantity ? 1 : 0.25 }}
            >
              <Text>
                {!/^AHS-/.test(value.sku) &&
                !/^HSA-/.test(value.sku) &&
                (value.vendor === 'AHS' || value.vendor === 'HSA')
                  ? `${value.vendor}-${value.sku}`
                  : value.sku}
                <span> ({` ${value.category}, ${value.subcategory}`})</span>
              </Text>
              <Text className="text-warning-800 font-sm">
                {value.description}
              </Text>
              {value.removalReason ? (
                <div className="flex items-center mt-2">
                  <IconWarningCircledFull
                    color="error"
                    width={16}
                    height={16}
                  />
                  <Text className="text-error font-sm ml-2">
                    {value.removalReason}
                  </Text>
                </div>
              ) : null}
              {!value.isAvailableForZip ? (
                <div className="flex items-center mt-2">
                  <IconWarningCircledFull
                    color="error"
                    width={16}
                    height={16}
                  />

                  <Text
                    className="text-error font-sm ml-2"
                    textTemplateKey="PRODUCT_UNAVAILABLE"
                  />
                </div>
              ) : null}
            </div>
          )
        },
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_PRICE"
          />
        ),
        accessor: 'price', // accessor is the "key" in the data
        Cell: ({
          value: { originalPrice, overrideReason, unitPrice, quantity },
        }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(unitPrice)}`}</Text>
            {originalPrice !== unitPrice &&
            typeof originalPrice === 'number' ? (
              <Tooltip
                content={
                  <TooltipContent
                    price={originalPrice as number}
                    reason={overrideReason}
                  />
                }
              >
                <IconQuestionMarkCircleFull
                  color="warning"
                  width={18}
                  height={18}
                />
              </Tooltip>
            ) : null}
          </div>
        ),
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_TAX"
          />
        ),
        accessor: 'unitTax', // accessor is the "key" in the data
        Cell: ({ value: { unitTax, quantity } }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(unitTax)}`}</Text>
          </div>
        ),
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_QTY"
          />
        ),
        accessor: 'quantity', // accessor is the "key" in the data
        Cell: ({ value }: any) => <Text variant="caption">{value}</Text>,
      },
      {
        Header: (
          <Text
            variant="heading-06"
            textTemplateKey="SHOPPING_CART_HEADER_TOTAL"
          />
        ),
        accessor: 'total', // accessor is the "key" in the data
        Cell: ({ value: { total, quantity } }: any) => (
          <div
            className="flex w-full items-center"
            style={{ opacity: quantity ? 1 : 0.25 }}
          >
            <Text variant="caption">{`${formatCentsCurrency(total)}`}</Text>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line
    [shoppingCartData]
  )

  return (
    <div className="h-full flex justify-center items-center">
      <article className="flex flex-col justify-center items-center">
        <AgentReplacementConfiramtionInfoList messageIndex={0} />
        <Text className="mb-4">
          Record ID:{' '}
          <Link
            to={`/queue#tab2?id=${savedReplacementId}`}
            className="text-caption text-interactive hover:text-interactive-900 inline-block cursor-pointer"
          >
            {savedReplacementId}
          </Link>
        </Text>
        <>
          <fieldset className="border border-gray p-3 rounded-2 relative mb-5">
            <legend>
              <div style={{ width: 30 }}>
                <Icon color="currentcolor" />
              </div>
            </legend>
            <Table
              id="shoppingCart-info-table-table"
              data={dataToDisplay}
              columns={columns}
              variant="light"
              sortable={false}
              paginate={false}
              hover="gray"
            />
            <div className="py-2 flex justify-end pr-2">
              <span className="text-2 block">Total :</span>
              <span className="ml-2 block">
                {formatCentsCurrency(
                  +dataToDisplay
                    .reduce((a, b) => a + Number(b.total.total), 0)
                    .toFixed(2)
                )}
              </span>
            </div>
          </fieldset>
        </>
        {(savedTenant === 'pc' &&
          formType.id ===
            '' +
              appliancepb.OutrightPaymentRequest.PaymentType
                .PAYMENT_TYPE_OUTRIGHT_PAYMENT) ||
        formType.id ===
          '' +
            appliancepb.OutrightPaymentRequest.PaymentType
              .PAYMENT_TYPE_RESTOCKING_FEE ? null : (
          <Button
            className="mb-4"
            id="outrigth-payment-order-button"
            label={!itemUnavailable ? 'Order' : 'Place this order externally'}
            onClick={handleOrder}
            loading={status === 'loading'}
            disabled={status === 'success' || itemUnavailable}
          />
        )}
        <Link to="/">
          <Button
            id="outrigth-payment-success-button"
            label={localizedText('OUTRIGHT_PAYMENT_SUCCESS_REDIRECT')}
            loading={status === 'loading'}
          />
        </Link>
        {message && <Text className={`text-${status} py-4`}>{message}</Text>}
      </article>
    </div>
  )
}

export default OutrightPaymentSuccess
