import {
    IconWarningCircledFull,
    Table,
    TableColumn,
  } from '@ftdr/blueprint-components-react'
  import {
    TextComponent as Text,
  } from 'src/components/custom-fdr-components'
  import React, { useMemo } from 'react'
  import 'src/screens/outright-payment-temp/shopping-cart/reset.css'
  import { formatCentsCurrency } from 'src/utils/internationalization-helper'
  import { IconQuestionMarkCircleFull } from '@ftdr/blueprint-components-react'
  import Tooltip from 'src/components/tooltip/tooltip'
  import TooltipContent from 'src/components/tooltip/tooltip-content'
  import Icon from 'src/screens/outright-payment-temp/shopping-cart/icon'
import { usePaymentModalContext } from 'src/hooks/use-payment-modal-context'
import { appliancepb } from 'src/services/protobuf-models/appliance-ms-protobuf-models'

  const CartDetailsTable = () => {
    const { cartInfoModal: data } = usePaymentModalContext()

    const cartItems = data['cartItems']

    const transformArray = (data: appliancepb.ICartItem[]) => {
        const transformed = data.map(provided => ({
          item: {
            sku: provided.sku,
            vendor: provided.vendor,
            category: provided.category,
            subcategory: provided.subcategory,
            description: provided.description,
            isAvailableForZip: provided.isAvailableForZip,
            quantity: provided.quantity,
          },
          quantity: provided.quantity,
          unitTax: provided.unitTax,
          total: (provided.unitPrice! + provided.unitTax!) * provided.quantity!,
          isAvailableForZip: provided.isAvailableForZip,
          price: {
            originalPrice: provided.originalPrice,
            overrideReason: "", // Assuming no override reason
            unitPrice: provided.unitPrice,
          },
        }))
        return transformed.map(
            ({ quantity, total, item, price, unitTax }) => ({
                item,
                quantity,
                unitTax: { unitTax, quantity },
                price: { ...price, quantity },
                total: { total, quantity },
              })
        )
      };

      const dataToDisplay = transformArray(cartItems)

    const columns = useMemo<TableColumn<any[]>[]>(
      () => [
        {
          Header: (
            <Text
              variant="heading-06"
              textTemplateKey="SHOPPING_CART_HEADER_ITEM"
            />
          ),
          accessor: 'item', // accessor is the "key" in the data
          Cell: ({ value }: any) => {
            return (
              <div
                className="cell-one"
                style={{ maxWidth: 580, opacity: value.quantity ? 1 : 0.25 }}
              >
                <Text>
                  {!/^AHS-/.test(value.sku) &&
                  !/^HSA-/.test(value.sku) &&
                  (value.vendor === 'AHS' || value.vendor === 'HSA')
                    ? `${value.vendor}-${value.sku}`
                    : value.sku}
                  <span> ({` ${value.category}, ${value.subcategory}`})</span>
                </Text>
                <Text className="text-warning-800 font-sm">
                  {value.description}
                </Text>
                {value.removalReason ? (
                  <div className="flex items-center mt-2">
                    <IconWarningCircledFull
                      color="error"
                      width={16}
                      height={16}
                    />
                    <Text className="text-error font-sm ml-2">
                      {value.removalReason}
                    </Text>
                  </div>
                ) : null}
                {!value.isAvailableForZip ? (
                  <div className="flex items-center mt-2">
                    <IconWarningCircledFull
                      color="error"
                      width={16}
                      height={16}
                    />
                    <Text
                      className="text-error font-sm ml-2"
                      textTemplateKey="PRODUCT_UNAVAILABLE"
                    />
                  </div>
                ) : null}
              </div>
            )
          },
        },
        {
          Header: (
            <Text
              variant="heading-06"
              textTemplateKey="SHOPPING_CART_HEADER_PRICE"
            />
          ),
          accessor: 'price', // accessor is the "key" in the data
          Cell: ({
            value: { originalPrice, overrideReason, unitPrice, quantity },
          }: any) => (
            <div
              className="flex w-full items-center"
              style={{ opacity: quantity ? 1 : 0.25 }}
            >
              <Text variant="caption">{`${formatCentsCurrency(unitPrice)}`}</Text>
              {originalPrice !== unitPrice &&
              typeof originalPrice === 'number' ? (
                <Tooltip
                  content={
                    <TooltipContent
                      price={originalPrice as number}
                      reason={overrideReason}
                    />
                  }
                >
                  <IconQuestionMarkCircleFull
                    color="warning"
                    width={18}
                    height={18}
                  />
                </Tooltip>
              ) : null}
            </div>
          ),
        },
        {
          Header: (
            <Text
              variant="heading-06"
              textTemplateKey="SHOPPING_CART_HEADER_TAX"
            />
          ),
          accessor: 'unitTax', // accessor is the "key" in the data
          Cell: ({ value: { unitTax, quantity } }: any) => (
            <div
              className="flex w-full items-center"
              style={{ opacity: quantity ? 1 : 0.25 }}
            >
              <Text variant="caption">{`${formatCentsCurrency(unitTax)}`}</Text>
            </div>
          ),
        },
        {
          Header: (
            <Text
              variant="heading-06"
              textTemplateKey="SHOPPING_CART_HEADER_QTY"
            />
          ),
          accessor: 'quantity', // accessor is the "key" in the data
          Cell: ({ value }: any) => (
            <Text variant="caption">{value}</Text>
          ),
        },
        {
          Header: (
            <Text
              variant="heading-06"
              textTemplateKey="SHOPPING_CART_HEADER_TOTAL"
            />
          ),
          accessor: 'total', // accessor is the "key" in the data
          Cell: ({ value: { total, quantity } }: any) => (
            <div
              className="flex w-full items-center"
              style={{ opacity: quantity ? 1 : 0.25 }}
            >
              <Text variant="caption">{`${formatCentsCurrency(total)}`}</Text>
            </div>
          ),
        },
      ],
      //eslint-disable-next-line
      [data]
    )
    return (
      <>
        <fieldset className="border border-gray p-3 rounded-2 relative">
          <legend>
            <div style={{ width: 30 }}>
              <Icon color='currentcolor' />
            </div>
          </legend>
          <Table
            id="shoppingCart-info-table-table"
            data={dataToDisplay}
            columns={columns}
            variant="light"
            sortable={false}
            paginate={false}
            hover="gray"
          />
          <div className="py-2 flex justify-end pr-5">
            <span className="text-2 block">Total :</span>
            <span className="mx-2 block">
              {formatCentsCurrency(
                +dataToDisplay
                  .reduce((a, b) => a + Number(b.total.total), 0)
                  .toFixed(2)
              )}
            </span>
          </div>
        </fieldset>
      </>
    )
  }

  export default CartDetailsTable
